<template>
    <v-container :key="componentKey">
        <kurcc-question-response v-for="(option, index) in options.check" :key="index"
                                 :response.sync="option.response"
                                 @click="setResponse(option.response)"/>
    </v-container>
</template>

<script>
import {ResponseType} from "@/modules/forms/enums/response-type";
import {questionOptions} from '@/modules/forms/constants/pre-defined-question-options'

export default {
    name: "KurccQuestionResponseList",
    props: ['showParentMenu'],
    components: {
        KurccQuestionResponse: () => import('@/modules/forms/components/question/KurccQuestionResponse'),
    },
    data() {
        return {
            componentKey: new Date().getTime()
        }
    },
    computed: {
        options() {
            return questionOptions
        },
        form() {
            return this.$store.state.forms.form
        },
        responseSets() {
            return this.form.responseSets
        }
    },
    watch: {
        form: {
            handler: function () {
                this.componentKey++
            },
            deep: true
        }
    },
    methods: {
        setResponse(response) {
            if (this.isDropDown(response) || this.isMultiChoice(response)) {
                if (this.aResponseSetIsSelected(response)) {
                    this.updateResponse(response)
                    this.hideParentMenu()
                }
            } else {
                this.$emit('update-response', response)
                this.$emit('update:showParentMenu', false)
            }
        },
        isDropDown(response) {
            return response.type === ResponseType.Dropdown
        },
        isMultiChoice(response) {
            return response.type === ResponseType["Multiple choice"]
        },
        aResponseSetIsSelected(response) {
            return !this.$lodash.isNil(response.responseSet)
        },
        updateResponse(response) {
            this.$emit('update-response', response)
        },
        hideParentMenu() {
            this.$emit('update:showParentMenu', false)
        }
    }
}
</script>
